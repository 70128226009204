.auto__title {
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin-bottom: 24px;
  color: #fbbe07;
}
.auto__subtitle {
  font-size: 20px;
  font-weight: 400;
  display: block;
  margin-top: 60px;
  margin-bottom: 24px;
  color: #fbbe07;
}
.form {
  padding-top: 24px;
}

.form__row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 36px;
}

.form__innerrow {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 24px;
}

.form__innerrow-reverse {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 24px;
}

.form__row-wide {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  margin-bottom: 36px;
}
.form__agreement {
  margin: 60px 0;
}

.input {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-bottom: 32px; */
}

.input__input {
  font-family: "Segoe UI";
  background-color: #f6f6f6;
  border: 1px solid #f6f6f6;
  font-size: 16px;
  line-height: 20px;
  height: 56px;
  padding: 0 20px;
  border-radius: 4px;
  resize: none;
  outline: none;
  color: #242729;
  appearance: none;
  box-sizing: border-box;
  flex: 1 1 auto;
}


.input__input::placeholder {
  color: #b2b5b8;
  opacity: 0.5;
}

.input__input:disabled {
  background-color: rgba(251, 190, 7, 0.2);
  border-color: #fbbe07;
}

.input__input_error {
  border-color: #eb5757;
}

.input__label {
  font-family: "Segoe UI";
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #242729;
  opacity: 0.8;
  margin-top: 20px;
  margin-bottom: 13px;
}


.input_error-text {
  display: none;
}

.input_error .input__input,
.input_error .select__current {
  border-color: #eb5757;
}

.input_error .input__label {
  display: flex;
}

.input_error .input_error-text {
  display: block;
  margin-top: 6px;
  color: #eb5757;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: none;
  white-space: nowrap;
  position: absolute;
  bottom: -16px;
  left: 0;
}

.label-valid {
  padding-left: 20px;
  position: relative;
}

.label-valid:before {
  content: "";
  background-image: url('/img/check.svg');
  width: 14px;
  height: 14px;
  position: absolute;
  top: 2px;
  left: 0;
}

.label-valid > .input_error {
  display: none;
}

.select {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width:100%;
}

.select_disabled .select__current::after {
  display: none;
}

.select__label {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #242729;
  opacity: 0.8;
  margin-top: 20px;
  margin-bottom: 13px
}

.select__current {
  cursor: pointer;
  position: relative;
  border: 1px solid #f6f6f6;
  background-color: #f6f6f6;
  font-size: 16px;
  line-height: 56px;
  height: 56px;
  padding: 0 20px;
  border-radius: 4px;
  resize: none;
  outline: none;
  color: #242729;
  appearance: none;
  box-sizing: border-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select__current::after {
  content: '';
  position: absolute;
  top: 25px;
  right: 12px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: #fbbe07;
  border-width: 0 0 2px 2px;
  transform: rotate(-45deg);
  transition: transform .3s ease;
}

.select__current_opened::after {
  transform: rotate(135deg);
}

.select__input {
  display: none;
}

.select__list {
  position: absolute;
  top: 94px;
  width: 100%;
  border-radius: 4px;
  max-height: 0;
  opacity: 0;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 9;
  overflow-y: scroll;
  /* overflow-y: hidden; */
  transition: 0.3s;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  margin-top: 18px;
}

.select__list_open {
  max-height: 285px;
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.select__list_open:hover {
  box-shadow: 0 22px 22px rgba(0, 0, 0, 0.13);
}

.select__item {
  font-size: 16px;
  line-height: 56px;
  color: #242729;
  padding: 0 20px;
  cursor: pointer;
  background-color: #fff;
  transition: 0.2s;
}

.select__item:hover {
  background-color: #f6f6f6;
}

.select__item_hidden {
  display: none;
}

.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.checkbox__text {
  font-size: 16px;
  line-height: 21px;
  color: #6d6d73;
  padding-left: 38px;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox__input:checked ~ .checkbox__span {
  background: #fbbe07;
}

.checkbox__input:checked ~ .checkbox__span::after {
  opacity: 1;
}

.checkbox__span {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  border-radius: 15%;
  box-sizing: border-box;
  border: 1px solid #fbbe07;
  transition: .15s;
}

.checkbox__span::after {
  content: '';
  position: absolute;
  opacity: 0;
  left: 7px;
  top: 3px;
  width: 7px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transition: .15s;
}

.auto-order .checkbox__text {
  padding-left: 53px;
  font-size: 18px;
  line-height: 24px;
  color: #242729;
}

.auto-order__rules .checkbox__span {
  width: 23px;
  height: 23px;
}

.auto-order__checkbox + .auto-order__checkbox {
  margin-top: 16px;
}


.auto-order__rules .checkbox__text {
  font-size: 16px;
  line-height: 21px;
  color: #6d6d73;
  padding-left: 39px;
}
.auto-order__text {
  margin-bottom: 24px;
}
.auto-insurance__rules-link {
  color: #fbbe07;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 30px;
  background: #fbbe07;
  color: #ffffff;
  font-size: 16px;
  border: 1px solid #fbbe07;
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 36px;
  min-width: 240px;
  transition: all 0.5ms;

}
.btn:hover, .btn:active, .btn:focus {
  background: #ffd453;
}
.btn__img {
  margin-left: 10px;
  padding-top: 2px;
}

.btn-disabled {
  background: #ffffff;
  color: #d9dbe1;
  border: 1px solid #d9dbe1;
  cursor: auto;
}
.btn-disabled img {
  display: none;
}
.btn-disabled:hover,
.btn-disabled:active,
.btn-disabled:focus {
    background: #ffffff;
  }


.auto__button {
  text-transform: uppercase;
  font-weight: 700;
}
.auto__button:hover .btn__img {
  transform: scale(1.1);
  transform: translate(6px, 0);
}

.auto__button-submit {
  text-transform: none;
  text-align: center;
  font-weight: 400;
}

.driver-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #fbbe07;
  border-radius: 4px;
  position: relative;
  padding: 12px;
  max-width: 100%;
  margin-bottom: 36px;
}
.driver-card__num {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 12px;
  top: -16px;
  width: 28px;
  height: 28px;
  padding: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #fbbe07;
}

.driver-card__button {
  align-self: flex-end;
  padding: 2px 0;
  border-bottom: 1px dashed #fbbe07;
  color: #fbbe07;
  background-color: #ffffff;
}
.driver-card__button-add {
  border-bottom: 1px solid #fbbe07;
}

.rules__check-error .checkbox__text {
  color: #eb5757;
}
.rules__check-error .checkbox__span {
  border: 1px solid #eb5757;
}

@media (max-width: 992px) {

  .form__row {
    grid-template-columns: 1fr;
  }

}
@media (max-width: 440px) {

  .form__innerrow {
    grid-template-columns: 1fr;
  }
  .form__innerrow-reverse {
    grid-template-columns: 1fr;
  }
}

.form__row-checkbox {
  margin-top: 60px;
  margin-bottom: 6px;
}
.form__row-checkbox > .checkbox {
  grid-column-start: 2;
  grid-column-end: 3;
  justify-self: end;
  margin-bottom: 0px;
}
